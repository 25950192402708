<template>
  <div class="layout">
    <div class="">
      <h2 class="text-left text-lg font-medium leading-6 text-gray-900">
        Sales
      </h2>

      <div class="mb-4 flex flex-row items-end justify-end">
        <el-date-picker
          v-model="selectedDate"
          type="daterange"
          range-separator="To"
          placeholder="Pick a day"
          :default-value="[new Date(), new Date()]"
        />
      </div>

      <div class="flex flex-col" v-if="salesByDay?.List?.length > 0">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <div class="mt-4 mb-8">
              <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div
                  class="overflow-hidden rounded-lg bg-white px-4 py-5 text-left shadow sm:p-6"
                >
                  <dt class="truncate text-sm font-medium text-gray-500">
                    Total Amount
                  </dt>
                  <dd
                    class="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
                  >
                    ${{ salesByDay?.TotalAmount?.toFixed(2) }}
                  </dd>
                </div>
                <div
                  class="overflow-hidden rounded-lg bg-white px-4 py-5 text-left shadow sm:p-6"
                >
                  <dt class="truncate text-sm font-medium text-gray-500">
                    Invoices Count
                  </dt>
                  <dd
                    class="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
                  >
                    {{ salesByDay?.TotalInvoicesCount }}
                  </dd>
                </div>
              </dl>
            </div>
            <div
              class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table
                class="min-w-full divide-y divide-gray-200"
                v-loading="loading"
              >
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Invoice Count
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="sales in salesByDay?.List" :key="sales.Id">
                    <td class="whitespace-nowrap px-6 py-4 text-left">
                      <div class="items-left flex max-w-sm truncate">
                        <div class="text-sm font-medium text-gray-900">
                          {{ formatDueDate(new Date(sales?.DayOfSale)) }}
                        </div>
                      </div>
                    </td>
                    <td
                      class="max-w-sm truncate whitespace-nowrap px-6 py-4 text-left"
                    >
                      <div class="text-sm text-gray-900">
                        {{ sales?.InvoicesCount }}
                      </div>
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-left">
                      <div class="text-sm text-gray-900">
                        ${{ sales?.DayTotalAmount?.toFixed(2) }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty
          description="Oops, no sales for selected date"
          :image-size="200"
        ></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import useReportsRepository from "@/composable/useReportsRepository";
import { ref, onMounted, watch } from "vue";
export default {
  setup() {
    const { loading, salesByDay, getSalesByDay } = useReportsRepository();

    const selectedDate = ref([new Date(), new Date()]);

    const formatDueDate = (date) => {
      if (date) {
        return new Intl.DateTimeFormat("en-US", {
          dateStyle: "full",
        }).format(date);
      }
      return "";
    };

    onMounted(() => {
      getSalesByDay(new Date(), new Date());
    });

    watch(selectedDate, (newVal) => {
      getSalesByDay(new Date(newVal[0]), new Date(newVal[1]));
    });

    return {
      loading,
      salesByDay,
      selectedDate,
      formatDueDate,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow-y: hidden;
  width: 100%;
  position: relative;
}
</style>
