import { ref, readonly } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";

export default function useReportsRepository() {
  const loading = ref(false);
  const salesByDay = ref([]);

  const getSalesByDay = async (startDate, endDate) => {
    console.log("--->", startDate, endDate);
    loading.value = true;
    try {
      let result = await API.Reports.salesByDay.get(startDate, endDate);
      console.log("result", result);
      salesByDay.value = result.data.Item;
      loading.value = false;
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while fetching sales by day",
        type: "warning",
      });
    }
  };

  return {
    loading: readonly(loading),
    salesByDay: readonly(salesByDay),
    getSalesByDay,
  };
}
